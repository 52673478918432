import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/Form2.css';

const Form2 = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telegram, setTelegram] = useState('');
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [buttonText, setButtonText] = useState("Получить предложение");

  const isFormValid = consent1 && consent2;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    setButtonText("Запрос отправлен");
    emailjs.sendForm(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE,
      e.target,
      process.env.REACT_APP_EMAIL_API
    )
      .then((result) => {
        console.log("Form submitted successfully:", result.text);
        e.target.reset();
        setName('');
        setEmail('');
        setTelegram('');
        setConsent1(false);
        setConsent2(false);
      }, (error) => {
        console.error("Failed to send form:", error.text);
      });
  };

  return (
    <form className="form2-block" onSubmit={handleSubmit} id="offer">
      <h2 className="form2-header">Специальное предложение!</h2>
      <p className="form2-description">
        Всем новым клиентам мы <b>дарим бесплатный аудит</b> для определения продуктов, которые помогут оптимизировать процессы и затраты
      </p>
      <div className="form2-inputs">
        <input
          type="text"
          name="user_name"
          placeholder="Имя и Фамилия"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          name="user_email"
          placeholder="Электронная почта"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          name="user_telegram"
          placeholder="Telegram ID"
          value={telegram}
          onChange={(e) => setTelegram(e.target.value)}
          required
        />
      </div>
      <div className="form2-consents">
        <div className="form2-consent">
          <input
            type="checkbox"
            id="consent1"
            checked={consent1}
            onChange={(e) => setConsent1(e.target.checked)}
          />
          <label htmlFor="consent1">
            Я согласен с обработку моих персональных данных в соответствии с <a href="https://businessneuro.ru/privacy/businessneuro_privacy.pdf" className="privacy-link" target="_blank" rel="noopener noreferrer">Политикой конфиденциальности</a>.
          </label>
        </div>
        <div className="form2-consent">
          <input
            type="checkbox"
            id="consent2"
            checked={consent2}
            onChange={(e) => setConsent2(e.target.checked)}
          />
          <label htmlFor="consent2">
          Даю согласие на получение ответа от компании ООО "Бизнес Нейросети" на мое обращение в соответствии с <a href="https://businessneuro.ru/privacy/businessneuro_agreement.pdf" className="privacy-link" target="_blank" rel="noopener noreferrer">Согласием на обработку персональных данных</a>.
          </label>
        </div>
      </div>
      <button type="submit" className="form2-button" disabled={!isFormValid}>
        {buttonText}
      </button>
    </form>
  );
};

export default Form2;
