import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/Form1.css';

function Form1() {
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [buttonText, setButtonText] = useState("Написать");

  const isFormValid = consent1 && consent2;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) return;

    setButtonText("Отправлено");

    emailjs.sendForm(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE,
      e.target,
      process.env.REACT_APP_EMAIL_API
    )
    .then((result) => {
      console.log("Form submitted successfully:", result.text);
      e.target.reset();
      setConsent1(false);
      setConsent2(false);
    }, (error) => {
      console.error("Failed to send form:", error.text);
    });
  };

  return (
    <form className="form1" onSubmit={handleSubmit}>
      <input type="text" name="user_name" placeholder="Имя и Фамилия" required />
      <input type="email" name="user_email" placeholder="Электронная почта" required />
      <input type="text" name="user_telegram" placeholder="Telegram ID" required />
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="consent1"
          checked={consent1}
          onChange={(e) => setConsent1(e.target.checked)}
        />
        <label htmlFor="consent1">
          Я согласен с обработку моих персональных данных в соответствии с{' '}
          <a
            href="https://businessneuro.ru/privacy/businessneuro_privacy.pdf"
            className="privacy-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Политикой конфиденциальности
          </a>.
        </label>
      </div>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="consent2"
          checked={consent2}
          onChange={(e) => setConsent2(e.target.checked)}
        />
        <label htmlFor="consent2">
          Даю согласие на получение ответа от компании ООО "Бизнес Нейросети" на мое обращение в соответствии с{' '}
          <a
            href="https://businessneuro.ru/privacy/businessneuro_agreement.pdf"
            className="privacy-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Согласием на обработку персональных данных
          </a>
        </label>
      </div>
      <button type="submit" disabled={!isFormValid}>
        {buttonText}
      </button>
    </form>
  );
}

export default Form1;
