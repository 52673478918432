import React, { useState } from 'react';
import '../styles/SolutionsBlock.css';
import SolutionCard from './SolutionCard';

import arrowNext from '../assets/icons/arrow_next.png';
import arrowPrev from '../assets/icons/arrow_prev.png';

const cardsData = [
  {
    id: 0,
    title: 'Чат-боты и виртуальные ассистенты',
    image: require('../assets/cards/card1.png'),
    text: 'Автоматизируйте клиентскую поддержку и коммуникацию с помощью умных чат-ботов, что повышает качество обслуживания и сокращает расходы.'
  },
  {
    id: 1,
    title: 'Анализ данных и прогнозирование трендов',
    image: require('../assets/cards/card2.png'),
    text: 'Передовые алгоритмы для обработки больших объёмов информации, выявления ключевых тенденций и принятия обоснованных управленческих решений.'
  },
  {
    id: 2,
    title: 'Компьютерное зрение и визуальный анализ',
    image: require('../assets/cards/card3.png'),
    text: 'Технологии распознавания изображений и видео для автоматизации контроля качества и мониторинга процессов, что повышает точность и эффективность работы.'
  },
  {
    id: 3,
    title: 'Автоматизация бизнес-процессов',
    image: require('../assets/cards/card4.png'),
    text: 'Оптимизируйте внутренние операции компании с помощью современных систем автоматизации, снижая операционные затраты и повышая общую производительность.'
  },
  {
    id: 4,
    title: 'Разработка материалов маркетинга',
    image: require('../assets/cards/card5.png'),
    text: 'Создаем уникальный и персонализированный контент, позволяя вашему бизнесу быстро запускать рекламные кампании и значительно снижать затраты на разработку креатива.'
  },
  {
    id: 5,
    title: 'Индивидуальные решения под ключ',
    image: require('../assets/cards/card6.png'),
    text: 'Уникальные технологии, адаптированные под специфические задачи вашего бизнеса. Инновационные алгоритмы для снижения затрат, достижения устойчивых конкурентных преимуществ.'
  }
];

const SolutionsBlock = () => {
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handlePrev = () => {
    setSelectedIndex((prevIndex) => (prevIndex - 1 + cardsData.length) % cardsData.length);
  };

  const handleNext = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % cardsData.length);
  };

  const handleDotClick = (dotIndex) => {
    setSelectedIndex(dotIndex);
  };

  return (
    <div className="solutions-block" id="solutions-block">
      <div className="solutions-text-block">
        <h2>Ваш бизнес — Наша задача</h2>
        <p>
          Мы предлагаем инновационные решения на базе машинного обучения, которые помогают оптимизировать
          процессы, снизить затраты и повысить эффективность управления. Наши технологии адаптированы под уникальные
          потребности вашего бизнеса, обеспечивая рост и конкурентные преимущества.
        </p>
      </div>

      <div className="cards-slider">
        <button className="arrow prev" onClick={handlePrev}>
          <img src={arrowPrev} alt="Предыдущая" />
        </button>
          {cardsData.map((card, index) => (
            <SolutionCard
              key={card.id}
              title={card.title}
              image={card.image}
              text={card.text}
              isActive={index === selectedIndex}
            />
          ))}
        <button className="arrow next" onClick={handleNext}>
          <img src={arrowNext} alt="Следующая" />
        </button>
      </div>

      <div className="dots-container">
        {[0, 1, 2, 3].map((dot, idx) => (
          <div
            key={idx}
            className={`dot ${selectedIndex % 4 === idx ? 'active' : ''}`}
            onClick={() => handleDotClick(idx)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default SolutionsBlock;