import React from 'react';
import '../styles/Form3.css';
import Form1 from './Form1';

const Form3 = () => {
  return (
    <div className="form3-block">
      <h2 className="form3-header">Подготовьте свой бизнес к будущему!</h2>
      <div className="form3-form-container">
        <Form1 />
      </div>
    </div>
  );
};

export default Form3;
