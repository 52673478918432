import React, { useState } from 'react';
import '../styles/CasesBlock.css';
import CaseCard from './CaseCard';

const casesData = [
    {
      id: 0,
      title: 'Промо-ролик',
      image: require('../assets/cases/case1.png'),
      text: 'Полностью сгенерированный промо-ролик для  стартапа ТРЕСТ.AI — проект занимается разработкой гибридной нейросети.',
      link: 'https://t.me/BusinessNeuronet/4'
    },
    {
      id: 1,
      title: 'ИИ-аватар "Meddy"',
      image: require('../assets/cases/case2.png'),
      text: 'Аватар "Meddy" ватар «Meddy» для педиатрического центра CMC (Palm Harbour, FL), помогающий справиться со стрессом детям.',
      link: 'https://t.me/BusinessNeuronet/6'
    },
    {
      id: 2,
      title: 'Генерация сайта',
      image: require('../assets/cases/case3.png'),
      text: 'Мы полностью сгенерировали сайт за 2 минуты (!!!) под ключ для Агентства премиальной недвижимости (Tampa, FL).',
      link: 'https://t.me/BusinessNeuronet/7'
    },
    {
      id: 3,
      title: 'Оценка рентабельности',
      image: require('../assets/cases/case4.png'),
      text: 'Внутрикорпоративное приложение для предиктивной аналитики рентабельности открытия точки (HoReCa). Модель обучена на большом объеме данных.',
      link: 'https://t.me/BusinessNeuronet/8'
    },
    {
      id: 4,
      title: 'ИИ-скрининг',
      image: require('../assets/cases/case5.png'),
      text: 'ИИ-скрининг HR-аналитики для наших клиентов из сферы EdTech. Модель экономит до 60% на первичный этап просмотра кандидатов и на 15% снижает коэффициент текучести кадров.',
      link: 'https://t.me/BusinessNeuronet/9'
    },
    {
      id: 5,
      title: 'Аналитика ЧПУ',
      image: require('../assets/cases/case6.png'),
      text: 'Система предиктивной аналитики для контроля и оптимизации работы станков с ЧПУ в сфере производства металлоконструкций и деталей высокой точности.',
      link: 'https://t.me/BusinessNeuronet/10'
    }
  ];
  
  const CasesBlock = () => {
    const [showAll, setShowAll] = useState(false);
  
    const displayedCases = showAll ? casesData : casesData.slice(0, 3);
  
    return (
      <div className="cases-block" id="cases-block">
        <div className="cases-text-block">
          <h2>Наши кейсы</h2>
          <p>
            Мы реализуем проекты, которые повышают эффективность бизнеса, оптимизируют процессы и создают новые конкурентные преимущества. Каждый кейс – это результат применения современных технологий и глубокого анализа потребностей клиента.
          </p>
        </div>
  
        <div className="cases-cards-grid">
          {displayedCases.map((item) => (
            <CaseCard
              key={item.id}
              title={item.title}
              image={item.image}
              text={item.text}
              link={item.link}
            />
          ))}
        </div>
  
        {casesData.length > 3 && (
          <button className="show-more-button" onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Скрыть' : 'Показать ещё'}
          </button>
        )}
      </div>
    );
  };
  
  export default CasesBlock;
