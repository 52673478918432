import React from 'react';
import '../styles/PartnersBlock.css';
import fasieImage from '../assets/partners/fasie.png';
import msuImage from '../assets/partners/msu.png';

const PartnersBlock = () => {
  return (
    <div className="partners-block" id="partners-block">
      <h2 className="partners-header">Подтвержденная экспертиза</h2>
      <div className="partners-images">
        <img src={fasieImage} alt="FASIE" className="partners-image" />
        <img src={msuImage} alt="MSU" className="partners-image" />
      </div>
      <div className="partners-description">
        <p>
          Мы гордимся нашими партнерами, которые подтверждают нашу экспертизу и уровень профессионализма.
        </p>
      </div>
    </div>
  );
};

export default PartnersBlock;
