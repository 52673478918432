import React from 'react';
import './Main.css';

import Header from './components/Header';
import MainBlock from './components/MainBlock';
import PartnersBlock from './components/PartnersBlock';
import SolutionsBlock from './components/SolutionsBlock';
import CasesBlock from './components/CasesBlock';
import Form2 from './components/Form2';
import FrameworksBlock from './components/FrameworksBlock';
import Form3 from './components/Form3';
import Footer from './components/Footer';

import './styles/Header.css';
import './styles/MainBlock.css';
import './styles/PartnersBlock.css';
import './styles/SolutionsBlock.css';
import './styles/CasesBlock.css';
import './styles/Form2.css';
import './styles/FrameworksBlock.css';
import './styles/Form3.css';
import './styles/Footer.css';

function Main() {
  return (
    <div className="Main">
      <Header />
      <MainBlock />
      <PartnersBlock />
      <SolutionsBlock />
      <CasesBlock />
      <Form2 />
      <FrameworksBlock />
      <Form3 />
      <Footer />
    </div>
  );
}

export default Main;
