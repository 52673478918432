import React from 'react';
import '../styles/SolutionCard.css';

const SolutionCard = ({ title, image, text, isActive }) => {
  return (
    <div className={`solution-card ${isActive ? 'active' : ''}`}>
      <img src={image} alt={title} className="card-image" />
      <h3 className="card-title">{title}</h3>
      <p className="card-text">{text}</p>
    </div>
  );
};

export default SolutionCard;
