import React from 'react';
import '../styles/FrameworksBlock.css';

const FrameworksBlock = () => {
    const frameworksImages = [];
    for (let i = 1; i <= 15; i++) {
      frameworksImages.push(require(`../assets/frameworks/image${i}.png`));
    }
  
    return (
      <div className="frameworks-block">
        <h2 className="frameworks-header">Современные и надежные инструменты</h2>
        <div className="frameworks-images-grid">
          {frameworksImages.map((imgSrc, index) => (
            <img
              key={index}
              src={imgSrc}
              alt={`Framework ${index + 1}`}
              className="frameworks-image"
            />
          ))}
        </div>
        <div className="frameworks-description">
          <p>
            При разработке продуктов мы используем самые передовые и надежные инструменты и фреймворки. Мы внимательно анализируем
            потребности и задачи, и выбираем наиболее оптимальные и эффективные подходы в области разработки.
          </p>
        </div>
      </div>
    );
  };
  
  export default FrameworksBlock;