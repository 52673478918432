import React from 'react';
import '../styles/Footer.css';
import logo from '../assets/logos/logo2.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <img src={logo} alt="Логотип компании" className="footer-logo" />
        <div className="footer-text">
          <p className="copy">© 2025 <b>ООО "Бизнес Нейросети"</b></p>
          <p>ИНН: 9729392472</p>
          <p>КПП: 772901001</p>
          <p>ОГРН: 1247700822654</p>
          <p className="address-title">Юридический адрес:</p>
          <p className="address-lines">119234, г Москва</p>
          <p className="address-lines">вн.тер.г муниципальный округ Раменки</p>
          <p className="address-lines">тер. Ленинские Горы, 1, строение, 77</p>
          <p className="links">
            <a href="https://businessneuro.ru/privacy/businessneuro_privacy.pdf" className="footer-link" target="_blank"><b>Политика конфиденциальности</b></a>
          </p>
          <p>
            По вопросам сопровождения сайта обращайтесь: <a href="mailto:contact@businessneuro.ru" className="footer-link">contact@businessneuro.ru</a>
          </p>
        </div>
      </div>
      <div className="footer-right">
        <a href="#main-block" className="footer-nav-link"><b>Главная</b></a>
        <a href="#partners-block" className="footer-nav-link"><b>Партнеры</b></a>
        <a href="#solutions-block" className="footer-nav-link"><b>Решения</b></a>
        <a href="#cases-block" className="footer-nav-link"><b>Кейсы</b></a>
      </div>
    </footer>
  );
};

export default Footer;
