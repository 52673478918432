import React from 'react';
import telegramIcon from '../assets/icons/tg_icon.png';
import logoImage from '../assets/logos/logo1.png';

const Header = () => {
  return (
    <header className="header">
      <div className="header-container">
        <div className="header-logo">
          <img src={logoImage} alt="Логотип компании" className="logo" />
        </div>
        <nav className="header-nav">
          <a href="#solutions-block" className="nav-button">Решения</a>
          <a href="#cases-block" className="nav-button">Кейсы</a>
          <a href="#offer" className="nav-button"><b>Спецпредложение!</b></a>
        </nav>
        <div className="header-social">
          <a
            href="https://t.me/BusinessNeuronet"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={telegramIcon} alt="Telegram" className="telegram-icon" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
