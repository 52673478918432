import React from 'react';
import '../styles/MainBlock.css';
import Form1 from './Form1';

const MainBlock = () => {
  return (
    <div className="main-block" id="main-block">
      <div className="upper-section">
        <div className="content-wrapper">
          <div className="text-content">
            <h1>Комплексная разработка и внедрение нейросетей в ваш бизнес</h1>
            <p>
              Решения на базе ИИ, которые упрощают управление, повышают эффективность и помогают бизнесу расти быстрее и выгоднее.
            </p>
          </div>
          <div className="form-container">
            <Form1 />
          </div>
        </div>
      </div>
      <div className="lower-section">
        <div className="company-info">
          Компания <b>Бизнес Нейросети</b> специализируется на разработке функциональных решений для бизнесов из различных сфер и направлений.<br /><br />
          Мы осуществляем свою деятельность с 2021 года, ранее будучи представленными на американском рынке.<br />
          <b>С 2024 года полностью переквалифицировались на деятельность на Российском рынке</b>.
        </div>
      </div>
    </div>
  );
};

export default MainBlock;
